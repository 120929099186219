<template>
  <div class="body">
    <div class="team-header">
      <div class="team-title">{{ $t("nav.team") }}</div>
      <div class="choose_option">
        <a
          class="layout__link__filters"
          :class="{ layout__link_visit: selectedSection === 'add' }"
          @click="showSection('add')"
        >
          <img src="@/assets/add.svg" alt="Добавить" class="icon" />
          Добавить
        </a>
        <a
          class="layout__link__filters"
          :class="{ layout__link_visit: selectedSection === 'filters' }"
          @click="showSection('filters')"
        >
          <img src="@/assets/filters.svg" alt="фильтр" class="icon" />
          Фильтры
        </a>
      </div>
    </div>
    <div
      class="text-fields-block block-wide"
      v-if="
        selectedSection === 'add' &&
        ($auth.check({ permissions: 'add_users' }) ||
          $auth.check({ name: 'admin' }))
      "
    >
      <form @submit.prevent="add_team">
        <team-text-fields
          ref="add_team"
          v-model="new_user"
          :for_table="false"
        ></team-text-fields>
        <team-access-block></team-access-block>
        <div class="text-fields-block__btn-row">
          <loader v-if="info.loader"></loader>
          <custom-btn
            v-else
            class="button button_theme_blue"
            type="submit"
            :disabled="disabled_button"
          >
            {{ $t("team.add_user") }}
          </custom-btn>
          <span
            class="text-fields-block__info-text text_color_green"
            :class="{ text_color_red: info.error }"
          >
            {{ info_text }}
          </span>
        </div>
      </form>
    </div>
    <div class="team__filter_row" v-if="selectedSection === 'filters'">
      <div class="team__filter-block">
        <select-filter
          class="monitoring__filter-block-item"
          :title="$t('team.city')"
          name="city"
          :items="city_filters"
          @change="set_filter"
        ></select-filter>
        <select-filter
          class="monitoring__filter-block-item"
          :title="$t('team.pos')"
          name="pos_name"
          :items="pos_filters"
          :disabled="pos_disabled"
          @change="set_filter"
        ></select-filter>
        <select-filter
          class="monitoring__filter-block-item"
          :title="$t('team.headers.authorized')"
          name="auth"
          :items="auth_filters"
          @change="set_filter"
        ></select-filter>
        <span
          class="team__filter-block-clear-btn"
          :class="{
            'team__filter-block-clear-btn_disabled': disabled_clear_btn,
          }"
          @click="clear_filters"
        >
          {{ $t("team.clear_filters") }}
        </span>
      </div>
    </div>
    <div class="team__table block-wide">
      <team-table
        :header_items="headers"
        :items="team"
        @change_sorted="change_sorted"
      ></team-table>
    </div>
  </div>
</template>

<script>
import CustomBtn from "@/components/recurringComponents/customBtn";
import TeamTextFields from "@/components/components/team/teamTextFields";
import TeamTable from "@/components/components/team/teamTable";
import teamAccessBlock from "../components/team/teamAccessBlock";
import Loader from "@/components/recurringComponents/loader";
import SelectFilter from "@/components/recurringComponents/selectFilter.vue";
export default {
  name: "team",
  components: {
    SelectFilter,
    Loader,
    TeamTable,
    TeamTextFields,
    CustomBtn,
    teamAccessBlock,
  },
  data() {
    return {
      selectedSection: null,
      info: {
        error: false,
        type: "",
        loader: false,
      },
      new_user: {
        first_name: "",
        last_name: "",
        employee_id: null,
      },
      headers: [
        {
          name: "employee_id",
          title: this.$i18n.t("team.headers.employee_id"),
          sorted: 1,
        },
        {
          name: "first_name",
          title: this.$i18n.t("team.headers.first_name"),
          sorted: 0,
        },
        {
          name: "last_name",
          title: this.$i18n.t("team.headers.last_name"),
          sorted: 0,
        },
        {
          name: "authorized",
          title: this.$i18n.t("team.headers.authorized"),
          sorted: 0,
        },
      ],
    };
  },
  created() {
    if (this.selected_filters.city.length != 0) {
      this.selected_filters.city = [];
    };

    if (this.selected_filters.pos_name.length != 0) {
      this.selected_filters.pos_name = [];
    };

    this.$store.dispatch("load_team");
    if (this.$store.getters.ALL_POSES.length == 0){
      this.$store.dispatch("load_all_poses");
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$socket.readyState === 1) {
        vm.$socket.sendObj({
          route: to.name,
          token: localStorage.getItem("access_token"),
        });
      }
    });
  },
  watch: {
    "$i18n.locale"() {
      this.refresh_header_titles_locale();
    },
    pos_disabled() {
      if (this.selected_filters.city.length === 0) {
        this.selected_filters.pos_name = [];
      }
    },
    pos_filters(newValue) {
      this.selected_filters.pos_name = this.selected_filters.pos_name.filter(
        (x) => newValue.some((item) => item.value === x)
      );
    },
    selected_filters: {
      handler() {
        this.$forceUpdate();
      },
      deep: true,
    },
  },
  computed: {
    filters() {
      return {
        city: this.$store.getters.FILTERS.city || [],
        pos_name: this.$store.getters.FILTERS.pos_name || [],
        auth_filters: this.$store.getters.FILTERS.auth_filters || [],
      };
    },
    selected_filters() {
      return this.$store.getters.SELECTED_FILTERS;
    },
    pos_disabled() {
      return this.selected_filters.city.length === 0;
    },
    disabled_button() {
      return !this.$store.getters.NEW_TEAM_ACCESS?.some(
        (x) => x.pos.length > 0
      );
    },

    city_filters() {
      var all_poses = this.$store.getters.ALL_POSES;
      
      const filters = Array.from(
        new Set(
          all_poses.map((pos) => pos.city.name)
        )
      ).map((item) => {
        return { title: item, value: item, selected: false };
      });
      console.log("Города:", filters);
      return filters;
    },

    pos_filters() {
      var all_poses = this.$store.getters.ALL_POSES;
      var cityFilters = this.selected_filters.city;
      var filters = [];
      
      for (let i = 0; i < all_poses.length; ++i) {
        if (cityFilters.includes(all_poses[i].city.name)) {
          filters.push({title: all_poses[i].name, value: all_poses[i].name, selected: false})
        }
      };

      console.log("POS:", filters);
      return filters;
    },

    auth_filters() {
      const authOptions = [
        { value: true, text: this.$t("status.info.yes") },
        { value: false, text: this.$t("status.info.no") },
      ];
      const mappedOptions = authOptions.map((item) => {
        return { title: item.text, value: item.value, selected: false };
      });
      console.log("Фильтры авторизации:", mappedOptions);
      return mappedOptions;
    },

    disabled_clear_btn() {
      return (
        !this.selected_filters.city.length &&
        !this.selected_filters.pos_name.length &&
        !this.selected_filters.auth.length
      );
    },
    team() {
      const teamData = this.$store.getters.TEAM;
      const filteredTeam = teamData.filter((employee) => {
        const cityMatch =
          this.selected_filters.city.length === 0 ||
          this.selected_filters.city.some(r => employee.city_names.includes(r));
        const posMatch =
          this.selected_filters.pos_name.length === 0 ||
          this.selected_filters.pos_name.some(r => employee.pos_names.includes(r));
        const authMatch =
          this.selected_filters.auth.length === 0 ||
          this.selected_filters.auth.includes(employee.auth);

        return cityMatch && posMatch && authMatch;
      });

      return filteredTeam.map((employee) => {
        return {
          id: employee.id,
          employee_id: employee.employee_id,
          auth: employee.auth,
          first_name: employee.name,
          last_name: employee.surname,
          city: employee.city,
          pos: employee.pos,
          authorized: employee.auth
            ? this.$t("status.info.yes")
            : this.$t("status.info.no"),
        };
      });
    },
    info_text() {
      switch (this.info.type) {
        case "required":
          return this.$i18n.t("errors.required");
        case "no_format":
          return this.$i18n.t("errors.format");
        case "no_letters":
          return this.$i18n.t("errors.no_letters");
        case "user_exists":
          return this.$i18n.t("team.loader.no_uid");
        case "no_auth":
          return this.$i18n.t("team.loader.no_auth");
        case "progress":
          return this.$i18n.t("team.loader.progress");
        case "failed":
          return this.$i18n.t("team.loader.failed");
        case "wrong_format":
          return this.$i18n.t("errors.numbers_letters_symbols");
        case "id_exist":
          return this.$i18n.t("team.loader.employee_id_exist");
        case "success":
          return "";
        default:
          return "";
      }
    },
  },
  methods: {
    showSection(section) {
      this.selectedSection = this.selectedSection === section ? null : section;
    },
    change_sorted(item) {
      let change_item = this.headers.find((it) => it.name === item);
      let sort = change_item.sorted;

      this.headers.forEach((item) => (item.sorted = 0));

      if (sort !== 1) change_item.sorted = 1;
      else change_item.sorted = -1;

      if (change_item.sorted !== 0) {
        this.team.sort((a, b) => {
          if (a[item] > b[item]) return change_item.sorted;
          if (a[item] === b[item]) return 0;
          if (a[item] < b[item]) return -change_item.sorted;
        });
      }
    },
    clear_filters() {
      if (!this.disabled_clear_btn) {
        this.$store.dispatch("clear_filters").then(() => {
          this.$store.dispatch("load_team");
        });
      }
    },
    // apply_filters() {
    //   const selectedCity = this.selected_filters.city;
    //   const selectedPosName = this.selected_filters.pos_name;
    //   const selectedAuth = this.selected_filters.auth.length > 0 ? this.selected_filters.auth : [];
    //
    //   console.log("Команда до применения фильтров:", JSON.parse(JSON.stringify(this.team)));
    //   console.log("Применяемые фильтры:", {
    //     city: selectedCity,
    //     pos_name: selectedPosName,
    //     auth: selectedAuth,
    //   });
    //
    //   const teamData = this.$store.getters.TEAM;
    //   const filteredTeam = teamData.filter(employee => {
    //     const cityMatch = selectedCity.length === 0 || selectedCity.includes(employee.city);
    //     const posMatch = selectedPosName.length === 0 || selectedPosName.includes(employee.pos_name);
    //     const authMatch = selectedAuth.length === 0 || selectedAuth.includes(employee.auth);
    //
    //     return cityMatch && posMatch && authMatch;
    //   });
    //
    //   this.$store.commit('SET_FILTERED_TEAM', filteredTeam);
    //   // this.$store.dispatch("load_team");
    //
    //   console.log("Команда после применения фильтров:", JSON.parse(JSON.stringify(filteredTeam)));
    // },
    validate() {
      let valid = this.$refs.add_team.validate();
      this.info.error = !valid.valid;
      this.info.type = valid.error;
      return !this.info.error;
    },
    set_filter(filter) {
      this.$store.dispatch("set_filters", filter);
    },
    add_team() {
      if (this.validate()) {
        this.info.loader = true;
        this.info.type = "progress";
        this.new_user.employee_id = this.new_user.employee_id.toString();
        this.new_user.available_pos = this.$store.getters.NEW_TEAM_ACCESS;
        this.$store
          .dispatch("add_employee", this.new_user)
          .then(() => {
            this.info.type = "success";
            this.new_user = {
              first_name: "",
              last_name: "",
              employee_id: null,
            };
          })
          .catch((err) => {
            this.info.error = true;
            if (
              err.response.data.errors.find((x) => x.message.includes("uid"))
            ) {
              this.info.type = "id_exist";
            } else {
              this.info.type = "failed";
            }
          })
          .finally(() => {
            this.info.loader = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
